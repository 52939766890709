<template>
  <div>
    <v-simple-table
      fixed-header
      height="calc(100vh - 145px)"
      class="table-padding-2"
    >
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th role="columnheader">
              <SelectWarehouse
                :label="$t('labels.warehouse')"
                :placeholder="$t('labels.warehouse')"
                name="id_warehouse"
                sort-name="warehouse_code"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <SelectPos
                :label="$t('labels.pos')"
                :placeholder="$t('labels.pos')"
                name="id_pos"
                sort-name="pos_code"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.use')"
                :placeholder="$t('labels.use')"
                name="customer"
                sort-name="customer"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.packaging_code')"
                :placeholder="$t('labels.packaging_code')"
                name="packaging_code"
                sort-name="packaging_code"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <SelectFilter
                :options="packagingTypes"
                :label="$t('labels.type')"
                :placeholder="$t('labels.type')"
                name="type"
                sort-name="type"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.name')"
                :placeholder="$t('labels.name')"
                name="name"
                sort-name="name"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <SelectFilter
                :options="packagingUnits"
                :label="$t('labels.unit')"
                :placeholder="$t('labels.unit')"
                name="unit"
                sort-name="unit"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.size_v')"
                :placeholder="$t('labels.size_v')"
                name="size"
                sort-name="size"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilterFromTo
                :label="$t('labels.available')"
                :placeholder="$t('labels.available')"
                name="available"
                sort-name="available"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" class="text-center">
              {{ $t("labels.forecast_out_of_stock") }}
              <!-- <InputFilterFromTo :label="$t('labels.forecast_out_of_stock')"
                                :placeholder="$t('labels.forecast_out_of_stock')" name="forecast_out_of_stock"
                                sort-name="forecast_out_of_stock" :sorting="filters.sort_by"
                                @onFilter="onFilterChange" @onSort="onSortChange" /> -->
            </th>
            <th role="columnheader" class="text-center">
              {{ $t("labels.supplier") }}
              <!-- <InputFilter :label="$t('labels.supplier')" :placeholder="$t('labels.supplier')" name="supplier"
                                sort-name="supplier" :sorting="filters.sort_by" @onFilter="onFilterChange"
                                @onSort="onSortChange" /> -->
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, key) in items"
            :key="`p_${key}_${item.id}`"
            class="text-center"
          >
            <td>{{ item.warehouse_code }}</td>
            <td>{{ item.pos_code }}</td>
            <td>
              <span
                v-if="item.id_warehouse"
                class="cursor-pointer text-decoration-underline primary--text"
                @click="showCustomerDialog(item)"
              >
                {{ item.customers || "Tất cả" }}
              </span>
            </td>
            <td>{{ item.code }}</td>
            <td>{{ item.type }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.unit }}</td>
            <td>{{ item.dxrxc }}</td>
            <td>{{ formatNumber(item.quantity) }}</td>
            <td>
              {{
                item.forecast_out_of_stock
                  ? `${item.forecast_out_of_stock} ngày`
                  : ""
              }}
            </td>
            <td>{{ item.last_supplier }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-row class="pt-3">
      <v-col cols="12" sm="4">
        <v-btn color="info" @click="exportExcel">
          <v-icon>mdi-download</v-icon>
          {{ $t("labels.excel") }}
        </v-btn>
      </v-col>
      <v-col cols="12" sm="4">
        <v-pagination
          v-model="page"
          :length="totalPage"
          :total-visible="5"
        ></v-pagination>
      </v-col>
      <v-col cols="12" sm="4" class="text-right">
        <v-btn color="success" class="mr-2" @click="showSupplierDialog">{{
          $t("labels.supplier")
        }}</v-btn>
        <v-btn color="primary" class="mr-2" @click="showPackagingDialog">{{
          $t("labels.packaging_code")
        }}</v-btn>
        <v-btn color="purple" class="white--text" @click="showHistoryDialog">{{
          $t("labels.history")
        }}</v-btn>
      </v-col>
    </v-row>

    <v-dialog v-model="supplierDialog" persistent max-width="100vw">
      <SupplierDialog v-if="supplierDialog" @cancel="hideSupplierDialog" />
    </v-dialog>

    <v-dialog v-model="packagingDialog" persistent max-width="960px">
      <CreateDialog v-if="packagingDialog" @cancel="hidePackagingDialog" />
    </v-dialog>

    <v-dialog v-model="customerDialog" persistent max-width="480px">
      <CustomerUseDialog
        v-if="customerDialog"
        @cancel="hideCustomerDialog"
        :updating-item="updatingItem"
      />
    </v-dialog>

    <v-dialog v-model="historyDialog" persistent max-width="1200px">
      <HistoryDialog v-if="historyDialog" @cancel="hideHistoryDialog" />
    </v-dialog>
  </div>
</template>

<script>
import { YES_NO_OPTIONS, PACKAGING_TYPES, PACKAGING_UNITS } from "@/libs/const";
import { httpClient } from "@/libs/http";
import { debounce } from "lodash/function";

export default {
  name: "Index",
  components: {
    SelectWarehouse: () => import("@/components/table/SelectWarehouse"),
    SelectPos: () => import("@/components/table/SelectPos"),
    SelectFilter: () => import("@/components/table/SelectFilter"),
    InputFilter: () => import("@/components/table/InputFilter"),
    InputFilterFromTo: () => import("@/components/table/InputFilterFromTo"),
    CreateDialog: () => import("@/components/packaging/CreateDialog"),
    SupplierDialog: () => import("@/components/packaging/SupplierDialog"),
    CustomerUseDialog: () => import("@/components/packaging/CustomerUseDialog"),
    HistoryDialog: () => import("@/components/packaging/HistoryDialog"),
  },
  data: () => ({
    page: 1,
    totalPage: 1,
    items: [],
    viewOption: "view0",
    filters: {},
    yesNoOptions: [...YES_NO_OPTIONS],
    packagingTypes: [...PACKAGING_TYPES],
    packagingUnits: [...PACKAGING_UNITS],
    isLoading: false,
    packagingDialog: false,
    supplierDialog: false,
    customerDialog: false,
    historyDialog: false,
    updatingItem: {},
    sum: {},
  }),
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    showSupplierDialog() {
      this.supplierDialog = true;
    },
    hideSupplierDialog() {
      this.supplierDialog = false;
    },
    showPackagingDialog() {
      this.packagingDialog = true;
    },
    hidePackagingDialog() {
      this.packagingDialog = false;
      this.getList();
    },
    showCustomerDialog(item) {
      this.updatingItem = { ...item };
      this.customerDialog = true;
    },
    hideCustomerDialog() {
      this.updatingItem = {};
      this.customerDialog = false;
      this.getList();
    },
    showHistoryDialog() {
      this.historyDialog = true;
    },
    hideHistoryDialog() {
      this.historyDialog = false;
    },
    setViewOption(mode) {
      this.viewOption = mode;
    },
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
    getList: debounce(function () {
      httpClient
        .post("/packaging/v1/quantity-list", {
          ...this.filters,
          page: this.page,
          viewMode: this.viewOption,
        })
        .then(({ data }) => {
          this.totalPage = data.totalPage;
          this.items = [...data.rows];
          this.sum = { ...data.sum };
        });
    }, 500),
    async exportExcel() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.waiting_file_download"));
        return false;
      }
      this.isLoading = true;
      const filename = `vat-tu.xlsx`;

      try {
        await this.downloadExcelFile(
          "/packaging/v1/quantity-export",
          { ...this.filters },
          filename
        );
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
  },
};
</script>
